
import { IRoleAccess, IUserRole } from '@/modules/system/types';
import $app from '@/plugins/modules';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Admin } from '@/modules/admin'

@Component
export default class RoleDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => null })
  readonly role: IUserRole | undefined;

  admin = $app.module(Admin);

  tab = 0;
  item: IUserRole | null = null;
  reseting = false;
  pwd = '';

  @Watch('value')
  onShow() {
    if (this.value) {
      this.item = $app.clone(this.role);
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  store() {
    this.$emit('store', this.item)
  }

  remove() {
    this.$emit('remove', this.item)
  }

  async switchAccess(access: IRoleAccess) {
    access.processing = true;
    try {
      if (!access.allowed) {
        await this.admin.$store.addRoleAccess(access);
      } else {
        await this.admin.$store.delRoleAccess(access);
      }
      access.allowed = !access.allowed;
    } catch (err) {
      $app.pushError(err);
    }
    access.processing = false;
  }
}
